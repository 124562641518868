import React, { useEffect } from "react";
import { AnimatePresence } from "framer-motion";
import { connect } from "react-redux";
import Main from "./pages/Main";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import DarkModeToggle from "./components/DarkModeToggle";
import Youtube from "./pages/Youtube";
import Nav from "./components/Nav";
import axios from "axios";
import {
  load,
  set_loading_more,
  set_piped_instances,
  set_searching,
  set_youtube_results,
  set_next_page,
} from "./redux/actions";
import { Helmet } from "react-helmet";
import { parse as parseURL } from "url";
import VideoPlayer from "./components/VideoPlayer";

const App = ({
  loaded,
  pipedInstances,
  set_piped_instances,
  set_youtube_results,
  searchTerm,
  settings,
  nextPage,
  videoSelected,
}) => {
  const location = useLocation();
  useEffect(() => {
    if (searchTerm) {
      set_searching(true);
      executeYoutubeSearch();
    }
  }, [searchTerm]);

  useEffect(() => {
    loadYoutubeVideos();
    if (!loaded)
      axios
        .get("/data")
        .then((res) => load(res.data))
        .catch((err) => {
          console.log("load error", err);
        });
  }, [loaded]);

  const loadMore = () => {
    set_loading_more(true);
    axios
      .get(nextPage)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log("load more error", err);
      });
  };

  const executeYoutubeSearch = (err) =>
    axios
      .get(
        (err
          ? pipedInstances[Math.floor(Math.random() * pipedInstances.length)]
          : "https://pipedapi.kavin.rocks") +
          "/search?q=" +
          encodeURIComponent(searchTerm.trim()) +
          "&filter=all"
      )
      .then((res) => {
        set_youtube_results(res.data.items);
        set_next_page(JSON.parse(res.data.nextpage).url);
        set_searching(false);
      })
      .catch((err) => {
        console.log("youtube search error", err);
        setTimeout(() => executeYoutubeSearch(true), 1000);
      });

  const loadYoutubeVideos = () =>
    axios
      .get(
        "https://raw.githubusercontent.com/wiki/TeamPiped/Piped-Frontend/Instances.md"
      )
      .then((res) => {
        const instances = res.data
          .split("\n")
          .map((line) => line.split("|")[1])
          .filter((entry) => {
            try {
              const parsed = parseURL(entry);
              if (!parsed.protocol) entry = "https://" + entry;
              new URL(entry);
              return entry.includes(".") && !entry.includes("..");
            } catch (err) {
              return false;
            }
          })
          .map((instance) => instance.trim());
        set_piped_instances(instances);
        const loadVideos = (err) => {
          axios
            .get(
              (err
                ? instances[Math.floor(Math.random() * instances.length)]
                : "https://pipedapi.kavin.rocks") + "/trending?region=US"
            )
            .then((res) => set_youtube_results(res.data))
            .catch((err) => {
              console.log("load youtube error", err);
              setTimeout(() => loadVideos(true), 1000);
            });
        };
        loadVideos();
      })
      .catch((err) => {
        console.log("youtube load error", err);
      });
  return (
    <div className="h-100 d-flex flex-column">
      <Helmet>
        <link
          rel="stylesheet"
          href={
            settings.theme === "dark"
              ? "/bootstrap/dark.css"
              : "/bootstrap/default.css"
          }
        />
      </Helmet>
      <Nav />
      <div className="flex-grow-1 h-0 position-relative">
        <AnimatePresence exitBeforeEnter>
          <Switch location={location} key={location.pathname}>
            <Route exact path="/">
              <div className="h-100 w-100 overflow-hidden">
                {typeof document !== "undefined" && <Main />}
              </div>
            </Route>
            <Route exact path="/youtube">
              <Youtube loadMore={loadMore} />
            </Route>
            <Route path="*">
              <Redirect to="/" />
            </Route>
          </Switch>
        </AnimatePresence>
        {videoSelected && <VideoPlayer />}
      </div>

      <DarkModeToggle />
    </div>
  );
};

const mapStateToProps = ({
  loaded,
  nextPage,
  pipedInstances,
  settings,
  videoSelected,
  searchTerm,
}) => ({
  loaded,
  nextPage,
  pipedInstances,
  searchTerm,
  settings,
  videoSelected,
});

export default connect(mapStateToProps, {
  load,
  set_loading_more,
  set_piped_instances,
  set_searching,
  set_youtube_results,
  set_next_page,
})(App);
